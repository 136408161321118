/* CSS Document */
/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
@media screen and (min-width: 1000px), print {
	html {
		font-size: 14px !important;
	}
}